import React from 'react';
import { Link } from 'react-router-dom';
import HomeJSON from './home.json';
// import Example from '../../assets/img/proyects/example.jpg';
import Example from '../../assets/img/proyects/example-white.png';
import './home.scss';

const Home = () => {
  const returnUrlImg = (url) => {
    const proyectImg = url.toLowerCase();
    const imgExample = Example;
    const splitUrlImg = imgExample.split('/',4)[3].split('.',1)[0].toLowerCase();
    console.log('proyectImg: ',proyectImg)
    console.log('splitUrlImg: ',splitUrlImg)
    if (proyectImg === splitUrlImg) {
      return Example;
    }
  }

  const homeOptions = () => HomeJSON.map( menu => (
    <div className="col-12 col-md-6 home-row-container" key={menu.id}>
      <Link to={menu.path} style={{textDecoration: 'none'}} >
        <div className="home-contenedor-img home-contenedor-img-hover" >
          <img className="d-block w-100 h-100" src={Example} alt={Example} />
          {/*<div className="home-mascara" style={{animationDelay: `${index+3}s`}}>*/}
          <div className="home-mascara">
            {/*<h2 style={{animationDelay: `${index+3}s`}}>{menu.name}</h2>*/}
            <h2>{menu.name}</h2>
          </div>
        </div>  
      </Link>
    </div>
  ));
  
  return (
    <div className="container home">
      <div className="row " style={{ background: 'transparent !important' }}>
        {homeOptions()}
      </div>
    </div>
  );
}

export default Home;