import React from 'react';
import Menu from '../../components/MenuRealState';
import Logo from '../../assets/img/footer.png';
import './contact-real-state.scss';

const ContactRealState = () => {  
  return (
    <React.Fragment>
      <Menu />
      <div className="container contact-real-state">
        <div className="row">
          <div className="col-12 contact-real-state-site__txt__container__logo">
            <img src={Logo} className="d-block contact-real-state-logo" alt='logo' />
          </div>
          <div className="col-12 contact-real-state-site__txt__container">
            <p>Calle Vicente Guerrero 22-C</p>
            <p>Centro</p>
            <p>Guadalupe, Zac.</p>
            <p>C.P. 98600</p>
          </div>
          <div className="col-12 contact-real-state-site__txt__container">
            <h4>Contáctanos</h4>
            <p>Para información y nuevo proyecto</p>
            <p>
              <a href = "mailto: email@example.com" target="__blank" className="contact-real-state-option">
                  email@example.com
                </a>
              </p>
            <p>
              <a href="https://api.whatsapp.com/send?phone=5555555555" target="__blank" className="contact-real-state-option">
                55-5555-5555
              </a>
            </p>
          </div>
          <div className="col-12 contact-real-state-site__txt__container">
            <h4>Talento</h4>
            <p>Para información y atracción de talento</p>
            <p>
              <a href = "mailto: email@example.com" target="__blank" className="contact-real-state-option">
                  email@example.com
                </a>
              </p>
            <p>
              <a href="https://api.whatsapp.com/send?phone=5555555555" target="__blank" className="contact-real-state-option">
                55-5555-5555
              </a>
            </p>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ContactRealState;