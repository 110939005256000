import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Logo from '../../assets/img/logo.png'; 
import './menu.scss';

const MenuRealState = () => {
  const location = useLocation();
  const currentLocation = location.pathname;

  useEffect(() => {
  }, [currentLocation]);

  return (
    <nav className="navbar navbar-expand-lg">
      <div className="container-fluid">
        <Link to='/' className="navbar-brand">
          <img 
            src={Logo} 
            alt='logo proyecto vertical'
            className='navbar-logo'  
          />
        </Link>
        <button 
          className="navbar-toggler" 
          type="button" 
          data-bs-toggle="collapse" 
          data-bs-target="#navbarSupportedContent" 
          aria-controls="navbarSupportedContent" 
          aria-expanded="false" 
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div 
          className="collapse navbar-collapse" 
          id="navbarSupportedContent"
        >
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <Link 
                to='/real-estate' 
                className={`nav-link ${currentLocation === '/real-estate' ? 'active' : ''}`}  
              >
                Inmuebles
              </Link>
            </li>
            {/*<li className="nav-item">
              <Link 
                to='/about' 
                className={`nav-link ${currentLocation === '/about' ? 'active' : ''}`}  
              >
                Taller
              </Link>
            </li>*/}
            <li className="nav-item">
              <Link 
                to='/contact-real-estate' 
                className={`nav-link ${currentLocation === '/contact-real-estate' ? 'active' : ''}`}  
              >
                Contacto
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default MenuRealState;