import React from 'react';
import { useLocation } from "react-router-dom";
import './proyect.scss';
import Menu from '../../components/Menu';
import Example from '../../assets/img/proyects/example.png';

const Proyect = () => {
  const location = useLocation();
  const { 
    from, 
    urlImg, 
    urlHeader, 
    urlLayout 
  } = location.state;


  console.log('from: ', from);
  console.log('urlImg: ', urlImg);
  console.log('urlHeader: ',urlHeader)

  return (
    <React.Fragment>
    <Menu />
      <div className="container proyect">
        <div className="row">
          <div className="col-12 proyect-header__img">
            <h1>{from.name}</h1>
            < img className="d-block w-100" src={urlHeader} alt={urlHeader} />
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-lg-6 proyect-presentation__txt__container">
            <p>{from.description}</p>
          </div>
          <div className="col-12 col-lg-6 proyect-img-ctn">
            <img className="d-block w-100 h-100" src={Example} alt={Example} />
          </div>
        </div>

        <div className="row">
          <div className="col-12 proyect-description__txt__container">
            <p>{from.description}</p>
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-sm-6">
            < img className="d-block w-100" src={urlHeader} alt={urlHeader} />
          </div>
          <div className="col-12 col-sm-6 proyect-resume__txt__container">
            <div>
              <p>{from.resume}</p>
              <p><strong>Proyecto: </strong>{from.specifications}</p>
              <p><strong>Tipo: </strong>{from.type}</p>
              <p><strong>Año: </strong>{from.year}</p>
              <p><strong>Ubicación: </strong>{from.location}</p>
              <p><strong>Área: </strong>{from.areas}</p>
            </div>
          </div>
        </div>

        

      </div>
    </React.Fragment>
  );
}

export default Proyect;