import React from 'react';
import { Link } from 'react-router-dom';
import './proyects.scss';
import ProyectsJSON from './proyects.json';
import Menu from '../../components/Menu';
import Example from '../../assets/img/proyects/example.png';
import Home from '../../assets/img/proyects/proyect.png';
import Layout from '../../assets/img/proyects/layout.png';

const Proyects = () => {
  const returnUrlImg = (url) => {
    const proyectImg = url.toLowerCase();
    const imgExample = Example;
    const imgHome = Home;
    const imgLayout = Layout;
    const splitUrlImg = imgExample.split('/',4)[3].split('.',1)[0].toLowerCase();
    const splitUrlImgHome = imgHome.split('/',4)[3].split('.',1)[0].toLowerCase();
    const splitUrlImgLayout = imgLayout.split('/',4)[3].split('.',1)[0].toLowerCase();
   
    if (proyectImg === splitUrlImg) {
      return Example;
    } else if (proyectImg === splitUrlImgHome) {
      return Home;
    } else if (proyectImg === splitUrlImgLayout) {
      return Layout;
    }

  }


  const proyects = () => ProyectsJSON.map( (proyect,index) => (
    <div 
      className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 proyects-col" 
      key={proyect.id}
    > 
      <Link 
        to={'/proyect'} 
        state={{ 
          from: {...proyect}, 
          urlImg: returnUrlImg(proyect.url),
          urlHeader: returnUrlImg(proyect.proyect.header),
          urlLayout: returnUrlImg(proyect.proyect.layout)
        }}
      >
        <div className="proyects-contenedor-img proyects-contenedor-img-hover" >
          <img className="d-block w-100 h-100" src={Example} alt={Example} />
          <div className="proyects-mascara" style={{animationDelay: `${index+3}s`}}>
            <h2 style={{animationDelay: `${index+3}s`}}>{proyect.name}</h2>
          </div>
        </div> 
      </Link>
    </div>
  ));
  
  return (
    <React.Fragment>
      <Menu />
    <div className="container proyects">
      <div className="row proyects-row-container">
        {proyects()}
      </div>
    </div>
  </React.Fragment>
  );
}

export default Proyects;