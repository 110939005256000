import React from 'react';
import Menu from '../../components/Menu';
import './not-found.scss';

const NotFound = () => {
  return (
    <React.Fragment>
      <div className="container not-found">
        <div className="row">
          <div className="col-12 not-found-text">
            <div className="row">
              <div className="col-12">
                <h1>¡Ho, oh!</h1>
              </div>
              <div className="col-12">
                <p>Page not found, please try again.</p>
                <p>página no encontrada, intenta nuevamente.</p>
              </div>
            </div>
          </div>
        </div>        
      </div>
    </React.Fragment>
  );
}

export default NotFound;