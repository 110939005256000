import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.scss';
import Home from './pages/Home';
import Proyects from './pages/Proyects';
import Proyect from './pages/Proyect';
import Contact from './pages/Contact';
import Footer from './pages/Footer';
import AboutUs from './pages/AboutUs';
import About from './pages/About';
import RealState from './pages/realEstate';
import ContactRealState from './pages/ContactRealState';
import AboutUsRealState from './pages/AboutUsRealState';
import NotFound from './pages/Notfound';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/proyects' element={<Proyects />} />
          <Route path='/proyect' element={<Proyect />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/about-us' element={<AboutUs />} />
          <Route path='/about' element={<About />} />
          <Route path='/real-estate' element={<RealState />} />
          <Route path='/contact-real-estate' element={<ContactRealState/>} />
          <Route path='/about-us-real-estate' element={<AboutUsRealState/>} />
          <Route path='/*' element={<NotFound/>} />
        </Routes>
        <Footer />
      </div>
    </Router>
    
  );
}

export default App;
