import React from 'react';
import Menu from '../../components/Menu';
import './about-us.scss';
import HeaderImg from '../../assets/img/about-us/header.jpg';
import MisionImg from '../../assets/img/about-us/mision.jpeg';
import VisionImg from '../../assets/img/about-us/vision.jpeg';

const AboutUs = () => {
  return (
    <React.Fragment>
      <Menu />
      <div className="container about-us">
        <div className="row">
          <div className="col-12 about-us-header">
            < img className="d-block w-100" src={HeaderImg} alt='header' />
          </div>
          <div className="col-12 col-md-6 about-us-description">
            <div>
              <h2>Nuestra Misión</h2>
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Corrupti earum temporibus iste minus, mollitia quo ipsa, ea ipsum vitae doloribus veritatis. Est eveniet in commodi quos neque laudantium, similique blanditiis?</p>
            </div>
          </div>
          <div className="col-12 col-md-6 about-us-description">
            < img className="d-block w-100" src={MisionImg} alt='header' />
          </div>
          <div className="col-12 about-us-description">
            <div>
              <h2>Nuestra História</h2>
              <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ratione ex, maiores facere mollitia autem, animi corporis sapiente ut laborum explicabo amet eos voluptates. Velit nemo molestiae minus nam nisi architecto.</p>
            </div>
          </div>
          <div className="col-12 col-md-6 about-us-description">
            < img className="d-block w-100" src={VisionImg} alt='header' />
          </div>
          <div className="col-12 col-md-6 about-us-description">
            <div>
              <h2>Nuestra Visión</h2>
              <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Magnam dolorum minus autem animi ratione quam ut. Quidem repellendus eius ad ipsum eligendi, eaque asperiores tempore odit excepturi alias est. Explicabo.</p>
            </div>
          </div>
        </div>        
      </div>
    </React.Fragment>
  );
}

export default AboutUs;