import React from 'react';
import MenuRealState from '../../components/MenuRealState';
// import Example from '../../assets/img/proyects/example.jpg';
import Example from '../../assets/img/proyects/example.png';
import './realState.scss';

const RealState = () => {
  return (
    <React.Fragment>
      <MenuRealState/>
      <div className="container realState">
        <div className="row " style={{ background: 'transparent !important' }}>
          <div className="col-12 col-md-6 realState-row-container">
            <a href='https://www.inmuebles24.com/inmobiliarias/proyecto-vertical_50926360-inmuebles.html' target='_blank' rel='noreferrer' style={{textDecoration: 'none'}} >
              <div className="realState-contenedor-img realState-contenedor-img-hover" >
                <img className="d-block w-100 h-100" src={Example} alt={Example} />
                <div className="realState-mascara">
                  <h2>inmuebles24</h2>
                </div>
              </div>  
            </a>
          </div>
          <div className="col-12 col-md-6 realState-row-container">
            <a href='https://www.lamudi.com.mx/proyecto-vertical-real-estate-proyecto-vertical-real-estate-agn/?script=23&gclid=CjwKCAjwt7SWBhAnEiwAx8ZLaj8zJTrAdpTCvPjoNe8CZPDi_4_JLUlivuCnOYOIYH2H3FEpCyrNkRoC9G8QAvD_BwE' target='_blank' rel='noreferrer' style={{textDecoration: 'none'}} >
              <div className="realState-contenedor-img realState-contenedor-img-hover" >
                <img className="d-block w-100 h-100" src={Example} alt={Example} />
                <div className="realState-mascara">
                  <h2>Lamudi</h2>
                </div>
              </div>  
            </a>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default RealState;