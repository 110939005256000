import React from 'react';
import Menu from '../../components/Menu';
import './about.scss';

const About = () => {
  return (
    <React.Fragment>
      <Menu />
      <div className="container about">
        <div className="row">
          <div className="col-12 about-text">
            <div className="row">
              <div className="col-12 col-md-4">
                <h3>Lorem ipsum dolor sit amet</h3>
              </div>
              <div className="col-12 col-md-8">
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga facilis asperiores obcaecati dignissimos ex optio quasi ipsum iste animi voluptas explicabo aliquam ea reiciendis exercitationem, aspernatur magnam rerum facere ab?</p>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga cumque consequatur est nesciunt eum libero beatae labore sint dicta dignissimos. Hic doloribus illo quaerat iusto.</p>
                <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Voluptate blanditiis voluptatum eos sunt, error minima deserunt sequi veniam obcaecati, optio iure in. Saepe magni aliquid qui ipsum animi culpa et at quam, laudantium amet exercitationem velit illo esse consequatur quod.</p>
              </div>
            </div>
          </div>
          <div className="col-12 about-text">
            <div className="row">
              <div className="col-12 col-md-4">
                <h3>Lorem ipsum dolor sit</h3>
              </div>
              <div className="col-12 col-md-8">
                <h4>Lorem, ipsum.</h4>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis, assumenda maiores alias architecto dolore nam nobis sint velit perferendis delectus, natus deserunt at numquam doloremque a accusantium, accusamus modi magnam repellendus aliquam est laudantium! Libero ipsam consequuntur provident minus expedita natus dolore, necessitatibus voluptatibus quisquam!</p>
                <h4>Lorem, ipsum.</h4>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Iste modi porro iusto hic reiciendis eos similique rerum magnam unde at vitae, aspernatur placeat cumque maiores minima odio eum veritatis architecto harum sed! Sint, qui vel.</p>
                <h4>Lorem, ipsum.</h4>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deleniti, sapiente.</p>
              </div>
            </div>
          </div>
          <div className="col-12 about-text">
            <div className="row">
              <div className="col-12 col-md-4">
                <h3>Lorem ipsum dolor sit amet consectetur adipisicing elit.</h3>
              </div>
              <div className="col-12 col-md-8">
                <h4>Lorem ipsum dolor sit amet.</h4>
                <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Inventore maiores nemo dolorem! Nam maxime dolorum enim nisi, perferendis sapiente eligendi iste laudantium.</p>
                <h4>Lorem ipsum dolor sit amet consectetur.</h4>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quae, sit? Cum dolorum quo maiores, optio rem praesentium rerum illum ratione. Officiis quae saepe aliquid quod aperiam cum ab esse, ut mollitia fuga, non, deleniti repudiandae libero?</p>
              </div>
            </div>
          </div>
          <div className="col-12 about-text">
            <div className="row">
              <div className="col-12 col-md-4">
                <h3>PUBLICACIONES</h3>
              </div>
              <div className="col-12 col-md-8">
                <h4>Lorem, ipsum dolor.</h4>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam vel alias accusantium soluta laudantium dolorum natus sapiente porro illum quasi repudiandae nostrum dignissimos beatae, eos quod ipsum doloribus deleniti, harum neque. Facilis nihil voluptas nemo tempora impedit blanditiis, quas aliquam?</p>
              </div>
            </div>
          </div>
        </div>        
      </div>
    </React.Fragment>
  );
}

export default About;